<template>
  <gf-table :data="materials" v-loading="loading">
    <el-table-column label="Name">
      <template #default="slot">
        <div class="font-weight-bolder font-size-sm">{{ slot.row.product.name }}</div>
        <div>{{ slot.row.variant.name }}</div>
      </template>
    </el-table-column>
    <el-table-column label="SKU" width="140" prop="variant.sku"></el-table-column>
    <el-table-column label="Quantity" width="120" prop="quantity">
      <template #default="slot">
        {{ $NumberFormat(slot.row.quantity, '00.0') }}
      </template>
    </el-table-column>
    <el-table-column label="Unit Price" width="130" prop="cost"></el-table-column>
    <el-table-column label="Total Price" width="180" prop="total">
      <template #default="slot">
        {{ $NumberFormat(slot.row.quantity * slot.row.cost, '00.0') }}
      </template>
    </el-table-column>
  </gf-table>
</template>

<script>
import CompanyMaterialService from '@/services/v1/CompanyMaterial'

export default {
  data () {
    return {
      materials: [],

      // ui controls
      loading: false
    }
  },
  methods: {
    async getMaterials () {
      try {
        this.loading = true

        const cmService = new CompanyMaterialService(this.$route.params.id)
        const result = await cmService.list()
        this.materials = result.data.rows
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    }
  },
  mounted () {
    this.$emit('update:active', 3)
    this.getMaterials()
  }
}
</script>
